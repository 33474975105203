import React from "react";
import { Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation, useTranslation } from "react-i18next";

import Layout from "../../components/layout"
import SEO from "../../components/seo";
import "../../components/i18n";
import Top from "../../views/top";
import Translations from "../../views/translations";
import Learning from "../../views/learning";
import About from "../../views/about";
import Contact from "../../views/contact";
import "../../styles/colors.scss";
import "../../styles/global.scss";

function IndexPage() {
  const { i18n, t } = useTranslation();
  i18n.changeLanguage('de');

  return (
    <Layout>
      <SEO 
        title={t("seo.title")}
        description={t("seo.description")}
        lang="de" />
      <Container fluid className="p-0">
        <Top/>
        <Translations/>
        <Learning/>
        <About/>
        <Contact/>
      </Container>
    </Layout>
  );
}

export default withTranslation()(IndexPage)
